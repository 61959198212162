import {
  Tooltip,
  Badge,
  tooltipClasses,
  styled,
  useTheme,
  Avatar,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import BusinessIcon from '@mui/icons-material/Business';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
        justify-content: flex-between;
        align-items: center;
`
);

const LogoImage = styled(Avatar)(
  () => `
        object-fit: cover;
        width: 55px;
        height: 55px;
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo() {
  const { t } = useTranslation();
  const theme = useTheme();
  const organisation = useSelector((state) => state.organisation);

  return (
    <TooltipWrapper title={t('Traxsmart Dashboard')} arrow>
      <LogoWrapper to="/">
        {organisation?.attributes?.logo && (
          <LogoImage
            src={`${organisation?.attributes?.logo}`}
            alt="traxsmart"
          />
        )}
        {!organisation?.attributes?.logo && (
          <Avatar sx={{ bgcolor: grey[700], ml: 1 }} variant="rounded">
            <BusinessIcon />
          </Avatar>
        )}
        <Typography
          sx={{
            ml: 1,
            fontSize: '1.5em',
            fontWeight: 'bold',
            color: 'text.secondary'
          }}
        >
          {organisation?.brandName || ''}
        </Typography>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
