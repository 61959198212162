import React from 'react';
import BrandAutocomplete from '.';

function BooleanAutocomplete({ onChange, value, name, label, placeholder }) {
  const options = [
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];
  return (
    <BrandAutocomplete
      label={label ?? 'Boolean Select'}
      placeholder={placeholder ?? 'Select value...'}
      onChange={onChange}
      value={value}
      name={name ?? 'booleanSelect'}
      options={options}
    />
  );
}

export default BooleanAutocomplete;
