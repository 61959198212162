import React, { useCallback, useEffect, useState } from 'react';
import BrandTable from 'src/components/BrandTable';
import useRefMounted from 'src/hooks/useRefMounted';
import MoneyService from 'src/services/Money.service';
import useAuth from 'src/hooks/useAuth';
import WalletService from 'src/services/erp/Wallet.service';
import DateTimeService from 'src/utils/DateTimeService';
import { Avatar, Chip, Typography } from '@mui/material';

function ResultTable({ filters, refresh, walletId, crdbDiff }) {
  const isMountedRef = useRefMounted();
  const { user } = useAuth();

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableConfig = [
    {
      Header: 'Date',
      accessor: ({ createdAt }) =>
        createdAt ? DateTimeService.getDateTimeString(createdAt) : '-'
    },
    {
      Header: 'Description',
      accessor: ({ description }) => description || '-'
    },

    {
      Header: 'Amount (₹)',
      accessor: ({ amount, transactionType }) =>
        amount ? (
          transactionType === 'CREDIT' ? (
            // <Chip
            //   color="success"
            //   avatar={<Avatar style={{ color: 'white' }}>₹</Avatar>}
            //   label={+amount}
            // />
            <Typography
              color="green"
              variant="h4"
            >{`+ ₹${MoneyService.fromServer(amount, true)} `}</Typography>
          ) : (
            <Typography color="red" variant="h4">{`- ₹${MoneyService.fromServer(
              amount,
              true
            )} `}</Typography>
          )
        ) : (
          '-'
        )
    }
  ];

  const csvTableConfig = [
    {
      Header: 'Date',
      accessor: ({ createdAt }) =>
        createdAt ? DateTimeService.getDateTimeString(createdAt) : '-'
    },
    {
      Header: 'Transaction Type',
      accessor: ({ transactionType }) => transactionType || '-'
    },
    {
      Header: 'Description',
      accessor: ({ description }) => description || '-'
    },
    {
      Header: 'Amount (in rupees)',
      accessor: ({ amount }) => (amount ? amount / 100 : '-')
    }
  ];

  const getTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await WalletService.getTransactions(walletId, {
        ...filters
      });

      if (isMountedRef.current) {
        setTransactions(response.data);
        const totalCredit = response.data
          .filter((transaction) => transaction.transactionType === 'CREDIT')
          .reduce((acc, transaction) => acc + transaction.amount, 0);

        const totalDebit = response.data
          .filter((transaction) => transaction.transactionType === 'DEBIT')
          .reduce((acc, transaction) => acc + transaction.amount, 0);

        crdbDiff(totalCredit, totalDebit);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [isMountedRef, filters, walletId, crdbDiff]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions, refresh, filters]);

  return (
    <BrandTable
      tableConfig={tableConfig}
      tableData={transactions}
      filterables={['name', 'description']}
      searchPlaceholder="Search Plans with name..."
      noResultsMessage="No Wallet Transactions found for the applied filters..."
      loading={loading}
      onDownload
      csvFileName="Transactions"
      csvFormat={csvTableConfig}
    />
  );
}

export default ResultTable;
