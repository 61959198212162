class PermissionService {
  flattenObject = (permissionObj) => {
    const p = Object.keys(permissionObj || {})
      ?.map((key) => {
        const _permis = [];
        if (permissionObj[key]?.allowed) _permis.push(key);
        if (permissionObj[key]?.actions)
          _permis.push(
            ...Object.keys(permissionObj[key]?.actions).filter(
              (_key) => !!permissionObj[key]?.actions[_key]
            )
          );

        return _permis;
      })
      .flat(Infinity)
      .filter(Boolean);

    console.log('permissions', p);

    return p;
  };
}

export default new PermissionService();
