import {
  Grid,
  Box,
  Autocomplete,
  Avatar,
  TextField,
  Chip,
  ListItem
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function BrandAutocomplete({
  label,
  placeholder,
  options,
  fullWidth,
  multiple,
  value,
  onChange,
  name,
  disabled,
  noLabel = false,
  createApiCall,
  deleteApiCall,
  deleteOnClear,
  xs,
  md,
  ...rest
}) {
  const { t } = useTranslation();

  const getDefaultValue = useCallback(() => {
    return multiple
      ? options?.filter((option) => value?.includes(option?.value))
      : options?.find((el) => el?.value === value);
  }, [options, value]);

  const [_value, _setValue] = useState(multiple ? [] : {});

  const _onChange = (_, selectedValue, actionType) => {
    if (actionType === 'clear' && deleteOnClear === true) {
      console.log(_value, 'delete all');

      _value.forEach(async (element) => {
        await deleteApiCall(element.value);
      });
    }
    _setValue(selectedValue);

    const values = multiple
      ? selectedValue?.map((el) => el?.value)
      : selectedValue?.value;

    onChange?.(name, values);
  };

  useEffect(() => {
    _setValue(getDefaultValue());
  }, [options]);

  return (
    <Grid item xs={xs ?? 12} md={md ?? 12}>
      {!noLabel && (
        <Box pb={1}>
          <b>{t(label)}:</b>
        </Box>
      )}
      <Autocomplete
        {...rest}
        multiple={multiple}
        filterSelectedOptions={multiple}
        sx={{ m: 0 }}
        onChange={_onChange}
        value={_value}
        options={options}
        disabled={disabled}
        defaultValue={getDefaultValue()}
        renderOption={(props, o) => {
          const { onClick, ...restProps } = props;
          return (
            <ListItem
              {...restProps}
              onClick={(e) => {
                if (createApiCall) createApiCall(o.value);
                onClick(e);
              }}
            >
              {o.avatar && (
                <Avatar
                  sx={{
                    mr: 1
                  }}
                  src={o.avatar}
                />
              )}

              {o.label}
            </ListItem>
          );
        }}
        getOptionLabel={(o) => o.label}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            name={name}
            fullWidth={fullWidth || true}
            InputLabelProps={{
              shrink: true
            }}
            placeholder={t(placeholder)}
          />
        )}
        renderTags={(members, getTagProps) =>
          members.map((ev, index) => (
            <Chip
              key={ev.label}
              label={ev.label}
              {...getTagProps({ index })}
              clickable
              onDelete={() => {
                if (deleteApiCall) deleteApiCall(ev.value);
                getTagProps({ index }).onDelete();
              }}
              avatar={ev.avatar && <Avatar src={ev.avatar} />}
            />
          ))
        }
      />
    </Grid>
  );
}

const createOption = (label, value) => ({ label, value });

const createOptionsFromArr = (arr, labelString, valueString) =>
  arr?.map((el) => createOption(el[labelString], el[valueString]));

export { createOption, createOptionsFromArr };

export default BrandAutocomplete;
