import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { RawLogsViewModule } from 'src/content/reports/RawLogs/module';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Alert = Loader(lazy(() => import('src/content/reports/Alert')));
const SosAlert = Loader(lazy(() => import('src/content/reports/SosAlert')));
const DutyReport = Loader(lazy(() => import('src/content/reports/DutyReport')));
// const Attendance = Loader(lazy(() => import('src/content/reports/Attendance')));
// const Fuel = Loader(lazy(() => import('src/content/reports/Fuel')));
// const Inspection = Loader(lazy(() => import('src/content/reports/Inspection')));
// const OfflineAging = Loader(
//   lazy(() => import('src/content/reports/OfflineAging'))
// );
// const SlaMatrixSummary = Loader(
//   lazy(() => import('src/content/reports/SlaMatrixSummary'))
// );
// const VehicleDuty = Loader(
//   lazy(() => import('src/content/reports/VehicleDuty'))
// );
const VehicleTimeline = Loader(
  lazy(() => import('src/content/reports/VehicleTimeline'))
);
// const StopReport = Loader(lazy(() => import('src/content/reports/StopReport')));
const MonthlySummary = Loader(
  lazy(() => import('src/content/reports/MonthlySummary'))
);
// const VehicleUsage = Loader(
//   lazy(() => import('src/content/reports/VehicleUsage'))
// );
// const D2DWasteCollection = Loader(
//   lazy(() => import('src/content/reports/D2DWasteCollection'))
// );
// const DumpToCompactor = Loader(
//   lazy(() => import('src/content/reports/DumpToCompactor'))
// );
// const DumpToDisposal = Loader(
//   lazy(() => import('src/content/reports/DumpToDisposal'))
// );
// const RouteViolation = Loader(
//   lazy(() => import('src/content/reports/RouteViolation'))
// );
// const OperationStatus = Loader(
//   lazy(() => import('src/content/reports/OperationStatus'))
// );
// const DumpYardMismatch = Loader(
//   lazy(() => import('src/content/reports/DumpYardMismatch'))
// );
// const DumpyardPresent = Loader(
//   lazy(() => import('src/content/reports/DumpyardPresent'))
// );

// const VehicleDisconnected = Loader(
//   lazy(() => import('src/content/reports/VehicleDisconnected'))
// );

// const CitizenComplaints = Loader(
//   lazy(() => import('src/content/reports/CitizenComplaints'))
// );

// const GeofenceEntryExit = Loader(
//   lazy(() => import('src/content/reports/GeofenceEntryExit'))
// );
// const DriverPerformance = Loader(
//   lazy(() => import('src/content/reports/DriverPerformance'))
// );
// const DeviceTampering = Loader(
//   lazy(() => import('src/content/reports/DeviceTampering'))
// );
// const VehicleAllocation = Loader(
//   lazy(() => import('src/content/reports/VehicleAllocation'))
// );
// const JobsReport = Loader(lazy(() => import('src/content/reports/JobsReport')));
// const LongStops = Loader(lazy(() => import('src/content/reports/LongStops')));

// const VehicleLoginLogoff = Loader(
//   lazy(() => import('src/content/reports/VehicleLoginLogoff'))
// );
// const DriverChange = Loader(
//   lazy(() => import('src/content/reports/DriverChange'))
// );

const reportRoutes = [
  { path: '/alert', element: <Alert /> },
  { path: '/sosalert', element: <SosAlert /> },
  { path: '/vehicle-timeline', element: <VehicleTimeline /> },
  { path: '/monthly-summary', element: <MonthlySummary /> },
  { path: '/duty-report', element: <DutyReport /> },
  { path: '/raw-logs', element: <RawLogsViewModule.Component /> }
  // { path: '/attendance', element: <Attendance /> },
  // { path: '/fuel-consumption', element: <Fuel /> },
  // { path: '/inspection', element: <Inspection /> },
  // { path: '/offline-aging', element: <OfflineAging /> },
  // { path: '/sla-matrix-summary', element: <SlaMatrixSummary /> },
  // { path: '/vehicle-duty', element: <VehicleDuty /> },
  // { path: '/stop-report', element: <StopReport /> },
  // { path: '/vehicle-usage', element: <VehicleUsage /> },
  // { path: '/d2d-waste-collection', element: <D2DWasteCollection /> },
  // { path: '/compactor-dumps', element: <DumpToCompactor /> },
  // { path: '/disposal-dumps', element: <DumpToDisposal /> },
  // { path: '/route-violation', element: <RouteViolation /> },
  // { path: '/operation-status', element: <OperationStatus /> },
  // { path: '/operation-status', element: <OperationStatus /> },
  // { path: '/dump-mismatch', element: <DumpYardMismatch /> },
  // { path: '/dumpyard-vehicle-presence', element: <DumpyardPresent /> },
  // { path: '/vehicle-disconnected', element: <VehicleDisconnected /> },
  // { path: '/citizen-complaints', element: <CitizenComplaints /> }
  // {
  //   path: '/geofence-entry-exits',
  //   element: <GeofenceEntryExit />
  // },
  // {
  //   path: '/driver-performance',
  //   element: <DriverPerformance />
  // },
  // {
  //   path: '/device-tampering',
  //   element: <DeviceTampering />
  // },
  // {
  //   path: '/vehicle-allocation',
  //   element: <VehicleAllocation />
  // },
  // {
  //   path: '/jobs',
  //   element: <JobsReport />
  // },
  // {
  //   path: '/long-stops',
  //   element: <LongStops />
  // },
  // {
  //   path: '/vehilce-login-logoff',
  //   element: <VehicleLoginLogoff />
  // },
  // {
  //   path: '/driver-change',
  //   element: <DriverChange />
  // }
];

export default reportRoutes;
