import React from 'react';
import BrandAutocomplete from '.';

function EnumAutocomplete({
  onChange,
  value,
  name,
  label,
  placeholder,
  ENUM = []
}) {
  const options = ENUM.map((option) => ({
    label: option,
    value: option
  }));
  return (
    <BrandAutocomplete
      label={label ?? 'ENUM Select'}
      placeholder={placeholder ?? 'Select value...'}
      onChange={onChange}
      value={value}
      name={name ?? 'enumSelect'}
      options={options}
    />
  );
}

export default EnumAutocomplete;
