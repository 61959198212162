import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const RawLogsViewModule = new Module({
  id: 'raw-logs',
  name: 'RawLogs',
  label: 'Raw Logs',
  path: 'raw-logs',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/RawLogs/View'))
  )
});
