import isProd from './isProd';

const DEV_URL = 'https://inventory-dev.parchai.io/v1';
const PROD_URL = 'https://inventory.parchai.io/v1';
const TEST_URL = 'http://localhost:9090/dev';

const BASE_URL = isProd ? PROD_URL : DEV_URL;

export const ACTIVATION_URL = `${BASE_URL}/activation`;
export const ACTIVATION_SUMMARY_URL = `${BASE_URL}/report/activation/summary`;
export const SALES_SUMMARY_URL = `${BASE_URL}/report/items/sell/summary`;
export const INSTOCK_SUMMARY_URL = `${BASE_URL}/report/warehouse/summary`;
export const ITEM_URL = `${BASE_URL}/item`;
export const WALLET_URL = `${BASE_URL}/wallet`;
export const USER_URL = `${BASE_URL}/user`;
