import React, { useEffect, useState } from 'react';
import RoleService from 'src/services/Role.service';
import useAuth from 'src/hooks/useAuth';
import BrandAutocomplete, { createOptionsFromArr } from '.';

function RoleAutocomplete({
  onChange,
  value,
  name,
  label,
  multiple = false,
  noLabel
}) {
  const { user } = useAuth();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await RoleService.get();

      setOptions(data || []);
    })();
  }, []);
  return (
    <BrandAutocomplete
      label={label ?? 'Roles'}
      noLabel={noLabel}
      placeholder="Select role..."
      onChange={onChange}
      value={value}
      multiple={multiple}
      name={name ?? 'role'}
      options={createOptionsFromArr(options, 'name', 'id')}
    />
  );
}

export default RoleAutocomplete;
