import {
  Typography,
  Box,
  styled,
  Avatar,
  alpha,
  lighten,
  Grid,
  Button,
  ButtonGroup
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Helmet } from 'react-helmet-async';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ExportIcon from '@mui/icons-material/FileDownloadRounded';
import { useNavigate } from 'react-router';
import Action from 'src/config/models/Action';
import useAuth from 'src/hooks/useAuth';
import checkActionAllowed from 'src/config/AccessControl/checkActionAllowed';

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        color: ${theme.colors.primary.main};
        margin-right: ${theme.spacing(2)};
        background: ${
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.8),
                0.2
              )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)`
            : `0px 2px 4px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.4
              )}, 0px 5px 16px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}`
        };
  `
);

function PageHeader({
  heading,
  subtitle,
  Icon,
  addRoute,
  addRouteAction,
  onExport,
  children
}) {
  const { t } = useTranslation();
  const { user, permissions } = useAuth();

  const navigate = useNavigate();

  const isAdmin = user.role === 'SUPER_ADMIN';

  const addEnabled =
    addRouteAction &&
    addRouteAction instanceof Action &&
    (isAdmin || checkActionAllowed(addRouteAction, permissions));

  return (
    <>
      <Helmet>
        <title>{heading}</title>
      </Helmet>
      <Grid item xs={12}>
        <PageTitleWrapper>
          <Box
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            gap={2}
          >
            <Box display="flex" alignItems="center">
              <AvatarPageTitle variant="rounded">
                {Icon ? (
                  <Icon fontSize="large" />
                ) : (
                  <SummarizeIcon fontSize="large" />
                )}
              </AvatarPageTitle>
              <Box>
                <Typography variant="h3" component="h3" gutterBottom>
                  {t(heading)}
                </Typography>
                <Typography variant="subtitle2">{t(subtitle)}</Typography>
              </Box>
            </Box>
            <Grid item>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                {children}
                {addRoute && (
                  <Button
                    sx={{
                      mt: { xs: 2, md: 0 }
                    }}
                    onClick={() => navigate(addRoute)}
                    startIcon={<AddRoundedIcon fontSize="small" />}
                  >
                    {t('Add')}
                  </Button>
                )}
                {addEnabled && (
                  <Button
                    sx={{
                      mt: { xs: 2, md: 0 }
                    }}
                    onClick={addRouteAction.run}
                    startIcon={<AddRoundedIcon fontSize="small" />}
                  >
                    {t(addRouteAction.label)}
                  </Button>
                )}
                {onExport && (
                  <Button
                    sx={{
                      mt: { xs: 2, md: 0 }
                    }}
                    startIcon={<ExportIcon fontSize="small" />}
                  >
                    {t('Export')}
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          </Box>
        </PageTitleWrapper>
      </Grid>
    </>
  );
}

export default PageHeader;
