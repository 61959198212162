export default class Action {
  id;
  name;
  label;
  run;
  meta = {};

  constructor({ id, name, label, run, meta }) {
    if (!name || !id || !label || !run)
      throw new Error('Action must have name, id, label and run');

    this.id = id;
    this.name = name;
    this.label = label;
    this.run = run;
    this.meta = meta || {};
  }

  static register(moduleActionObject) {
    return (run) => new this({ ...moduleActionObject, run });
  }

  copyWith({ id, name, label, run, meta }) {
    return new Action({
      id: id ?? this.id,
      name: name ?? this.name,
      label: label ?? this.label,
      run: run ?? this.run,
      meta: meta ?? this.meta
    });
  }

  withMeta(meta) {
    return this.copyWith({ meta });
  }
}
