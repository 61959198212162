import Action from '../models/Action';

export default function checkActionAllowed(action, permissions) {
  if (!action) return false;
  if (!(action instanceof Action))
    throw new Error('action passed must be an instance of Action');

  // console.log('checking permissions for action ', action, permissions);

  return permissions?.includes(action.id);
}
