import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const VehicleStatusViewDashboardModule = new Module({
  id: 'vehicleStatus',
  name: 'Device Status',
  path: 'deviceStatus',
  Component: ComponentLoader(
    lazy(() => import('src/content/dashboards/VehicleStatus'))
  ),
  actions: {
    seeStatusliveTracking: {
      id: 'see-status-live-tracking',
      name: 'change-vehicleStatus-live-tracking',
      label: 'See Live Tracking Status'
    },
    seeStatusRoutePlayback: {
      id: 'see-status-route-playback',
      name: 'see-vehicleStatus-route-playback',
      label: 'See Route Playback'
    },
    generateCertificate: {
      id: 'generate-certificate',
      name: 'generate-vehicleStatus-certificate',
      label: 'Generate Certificate'
    },
    editCertificate: {
      id: 'edit-certificate',
      name: 'edit-vehicleStatus-certificate',
      label: 'Edit Certificate'
    },
    viewCertificate: {
      id: 'view-certificate',
      name: 'view-vehicleStatus-certificate',
      label: 'View Certificate'
    }
  }
});

const VehicleStatusDashboardModules = [VehicleStatusViewDashboardModule];

export default new ModuleBundle(
  'Vehicle Status Dashboard',
  VehicleStatusDashboardModules
);
