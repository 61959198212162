/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
const moment = require('moment-timezone');
// const tz = require("")

const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'hh:mm A';
const timeFormat24 = 'HH:mm:ss';

export default class DateTimeService {
  static initDate(dateTime) {
    return moment(dateTime).tz('Asia/Kolkata');
  }

  static getDateTimeString(dateTime) {
    return this.initDate(dateTime).format(`${dateFormat} ${timeFormat24}`);
  }

  static get24HrsDateTimeString(dateTime) {
    return this.initDate(dateTime).format(`${dateFormat} ${timeFormat24}`);
  }
  static getDateTimeStringWithSecs(dateTime) {
    return this.initDate(dateTime).format(`${dateFormat} hh:mm:ss A`);
  }

  static getTimeString(dateTime) {
    return this.initDate(dateTime).format(`${timeFormat24}`);
  }

  static get24TimeStringUTC(dateTime) {
    return this.initDate(dateTime).format(`${timeFormat24}`);
  }

  static getJoinedTime(...time) {
    return time.slice(0, 2).join(':');
  }

  static getDateString(dateTime) {
    return this.initDate(dateTime).format(`${dateFormat}`);
  }

  static getDifference(startTime, endTime) {
    return moment.duration(this.initDate(endTime).diff(startTime, null, true));
  }

  static getDifferenceInDays(startTime, endTime) {
    return this.getDifference(startTime, endTime).asDays();
  }

  static getDifferenceInHours(startTime, endTime) {
    return this.getDifference(startTime, endTime).asHours();
  }

  static getDiffInHoursFromDateTimeString(
    startDateTimeString,
    endDateTimeString
  ) {
    const startTime = moment(startDateTimeString);
    const endTime = moment(endDateTimeString);
    const diffInHours = endTime.diff(startTime, 'hours');
    return diffInHours;
  }

  static getDifferenceInMinutes(startTime, endTime) {
    return this.getDifference(startTime, endTime).asMinutes();
  }

  static getDifferenceInSeconds(startTime, endTime) {
    return this.getDifference(startTime, endTime).asSeconds();
  }

  static getTimeAgo(startTime) {
    const currentTime = moment();
    const duration = this.getDifference(startTime, currentTime);

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const seconds = Math.floor(duration.asSeconds()) % 60;

    const parts = [];

    if (hours > 0) {
      parts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} ${minutes === 1 ? 'min' : 'mins'}`);
    }
    if (seconds > 0 || (hours === 0 && minutes === 0)) {
      parts.push(`${seconds} ${seconds === 1 ? 'sec' : 'secs'}`);
    }

    return parts.join(' ');
  }

  static calculateTimeAgo(timestamp) {
    const now = moment();
    const then = moment(timestamp);
    const duration = moment.duration(now.diff(then));

    let timeAgo;

    if (duration.asSeconds() < 1) {
      timeAgo = 'less than a second ago';
    } else if (duration.asSeconds() < 60) {
      timeAgo = `${Math.floor(duration.asSeconds())} seconds ago`;
    } else if (duration.asMinutes() < 1) {
      timeAgo = 'a minute ago';
    } else if (duration.asMinutes() < 60) {
      timeAgo = `${Math.floor(duration.asMinutes())} minutes ago`;
    } else if (duration.asHours() < 1) {
      timeAgo = 'an hour ago';
    } else if (duration.asHours() < 24) {
      timeAgo = `${Math.floor(duration.asHours())} hours ago`;
    } else if (duration.asDays() < 1) {
      timeAgo = 'a day ago';
    } else {
      timeAgo = `${Math.floor(duration.asDays())} days ago`;
    }

    return timeAgo;
  }

  static getCombinedDateTimeString(date, time) {
    const combinedDate = moment(date)
      .hour(moment(time).hour())
      .minute(moment(time).minute());
    return combinedDate.toISOString();
  }

  static getDifferenceInMiliseconds(startTime, endTime) {
    return this.getDifference(startTime, endTime).asMilliseconds();
  }

  static getDateTimeFromTimeString(timeString) {
    const timeStamp = new Date().toISOString();
    const datePart = timeStamp.split('T')[0];
    return this.initDate(`${datePart}T${timeString}Z`);
  }

  static getFormatedTimeDiff(startTime, endTime) {
    let diff = this.getDifferenceInSeconds(startTime, endTime);

    const hrs = Math.floor(diff / (60 * 60));

    diff -= hrs * (60 * 60);

    const mins = Math.floor(diff / 60);

    diff -= mins * 60;

    const secs = Math.floor(diff);

    let returnString = `${secs} secs`;

    if (mins) returnString = `${mins} mins ${returnString}`;

    if (hrs) returnString = `${hrs} hrs ${returnString}`;

    return returnString;
  }

  static getDateTimeNDaysAgo(datetime, days) {
    return this.initDate(datetime).subtract(days, 'days');
  }

  static getMidnightTime(datetime) {
    return this.initDate(datetime).endOf('day').toDate();
  }
  static getMorningTime(datetime) {
    return this.initDate(datetime).startOf('day').toDate();
  }

  static getNewDateFromMilliseconds(millisecondsToAdd) {
    const currentTimestamp = Date.now();
    const newTimestamp = currentTimestamp + millisecondsToAdd;
    const newMoment = this.initDate(newTimestamp).toDate();
    return newMoment;
  }

  static getDaysFromArray(daysArr) {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    return daysArr?.map((idx) => days[idx - 1]).join(', ');
  }

  static getIndexfromDays(daysString) {
    const days = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ];
    return daysString?.split(',').map((day, i) => {
      return days.indexOf(day.toString().toLowerCase()) + 1;
    });
  }

  static getDaysObject(daysArr) {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    let daysObj = {};
    for (const [idx] of days.entries())
      daysObj[idx + 1] = !!daysArr.includes(idx + 1);
    return daysObj;
  }

  static toServerTime(momentDate) {
    return momentDate.utc(String).format();
  }

  static toServerDate(csvdate) {
    const [month, date, year] = csvdate.split('/');
    const serverDate = new Date(`${year}-${month}-${date}`);
    return serverDate.toISOString();
  }

  static mergeDateAndTimeString(datestring, timestring) {
    const [dateComponent] = datestring.split('T');
    const [_, timeComponent] = timestring.split('T');
    return `${dateComponent}T${timeComponent}`;
  }
}
