import { mock } from 'src/utils/axios';

let dumpyardMismatch = [
  {
    date: "8th May' 22",
    route: 'Ward 025 Tipper route',
    vehicleRegNo: 'C8/W51-A/RJ01GB7858',
    ward: 'Ward 025',
    circle: 'Circle 08',
    driverName: 'Jagat',
    driverContact: '9937789586',
    wrongDisposals: 2
  },
  {
    date: "8th May' 22",
    route: 'Ward 020 Tipper route',
    vehicleRegNo: 'C9/W72-B/RJ01GB7902',
    ward: 'Ward 020',
    circle: 'Circle 03',
    driverName: 'Ramanujan',
    driverContact: '7657789516',
    wrongDisposals: 3
  },
  {
    date: "8th May' 22",
    route: 'Ward 025 Tipper route',
    vehicleRegNo: 'C4/W22-A/RJ01GB7909',
    ward: 'Ward 025',
    circle: 'Circle 013',
    driverName: 'Harendra',
    driverContact: '6347959535',
    wrongDisposals: 0
  },
  {
    date: "8th May' 22",
    route: 'Ward 046 Tipper route',
    vehicleRegNo: 'C8/W55-B/RJ01GB7883',
    ward: 'Ward 046',
    circle: 'Circle 011',
    driverName: 'Ganga Ram',
    driverContact: '7237616573',
    wrongDisposals: 0
  },
  {
    date: "8th May' 22",
    route: 'Ward 030 Tipper route',
    vehicleRegNo: 'C10/W74-A/RJ01GB7870',
    ward: 'Ward 030',
    circle: 'Circle 012',
    driverName: 'Gurupreet',
    driverContact: '7826542559',
    wrongDisposals: 0
  },
  {
    date: "8th May' 22",
    route: 'Ward 035 Tipper route',
    vehicleRegNo: 'C8/W54-A/RJ01GB7894',
    ward: 'Ward 035',
    circle: 'Circle 013',
    driverName: 'Devilal',
    driverContact: '7264651607',
    wrongDisposals: 0
  },
  {
    date: "8th May' 22",
    route: 'Ward 050 Tipper route',
    vehicleRegNo: 'C1/W2-A/RJ01GB7881',
    ward: 'Ward 050',
    circle: 'Circle 014',
    driverName: 'Damodhara',
    driverContact: '9816750609',
    wrongDisposals: 0
  },
  {
    date: "8th May' 22",
    route: 'Ward 02 Tipper route',
    vehicleRegNo: 'C10/W77-B/RJ01GB7867',
    ward: 'Ward 02',
    circle: 'Circle 05',
    driverName: 'Arjun',
    driverContact: '8831649443',
    wrongDisposals: 0
  },
  {
    date: "8th May' 22",
    route: 'Ward 015 Tipper route',
    vehicleRegNo: 'C5/W32-B/RJ01GB7891',
    ward: 'Ward 015',
    circle: 'Circle 01',
    driverName: 'Bharat',
    driverContact: '7067178616',
    wrongDisposals: 2
  },
  {
    date: "8th May' 22",
    route: 'Ward 042 Tipper route',
    vehicleRegNo: 'C8/W60-B/RJ01GB6878',
    ward: 'Ward 042',
    circle: 'Circle 010',
    driverName: 'Amrinder',
    driverContact: '7837659586',
    wrongDisposals: 5
  }
];

mock.onGet('/api/dump-mismatch').reply(() => {
  return [200, { dumpyardMismatch }];
});
