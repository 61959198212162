import {
  Button,
  Card,
  Box,
  Grid,
  Typography,
  useTheme,
  styled,
  Avatar,
  Divider,
  alpha,
  ListItem,
  ListItemText,
  List,
  ListItemAvatar
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TrendingUp from '@mui/icons-material/TrendingUp';
import Text from 'src/components/Text';
import Chart from 'react-apexcharts';
import MoneyService from 'src/services/Money.service';
import { TrendingDown } from '@mui/icons-material';
import { abs } from 'stylis';
import { useState } from 'react';
import AddMoneyModal from './AddMoneyModal';

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
      box-shadow: ${theme.colors.shadows.success};
`
);
const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.main};
      color: ${theme.palette.error.contrastText};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
      box-shadow: ${theme.colors.shadows.error};
`
);

const ListItemAvatarWrapper = styled(ListItemAvatar)(
  ({ theme }) => `
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing(1)};
  padding: ${theme.spacing(0.5)};
  border-radius: 60px;
  background: ${
    theme.palette.mode === 'dark'
      ? theme.colors.alpha.trueWhite[30]
      : alpha(theme.colors.alpha.black[100], 0.07)
  };

  img {
    background: ${theme.colors.alpha.trueWhite[100]};
    padding: ${theme.spacing(0.5)};
    display: block;
    border-radius: inherit;
    height: ${theme.spacing(4.5)};
    width: ${theme.spacing(4.5)};
  }
`
);

function WalletBalance({ balance, isGain, deltaBalance }) {
  deltaBalance = abs(deltaBalance);
  const { t } = useTranslation();
  const theme = useTheme();

  const [addMoneyModalOpen, setAddMoneyModalOpen] = useState(false);

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%'
        }
      }
    },
    colors: ['#ff9900', '#1c81c2', '#333', '#5c6ac0'],
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${val}%`;
      },
      style: {
        colors: [theme.colors.alpha.trueWhite[100]]
      },
      background: {
        enabled: true,
        foreColor: theme.colors.alpha.trueWhite[100],
        padding: 8,
        borderRadius: 4,
        borderWidth: 0,
        opacity: 0.3,
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: theme.colors.alpha.black[70],
          opacity: 0.5
        }
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: theme.colors.alpha.black[50],
        opacity: 0.5
      }
    },
    fill: {
      opacity: 1
    },
    labels: [t('Bitcoin'), t('Ripple'), t('Cardano'), t('Ethereum')],
    legend: {
      labels: {
        colors: theme.colors.alpha.trueWhite[100]
      },
      show: false
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  const chartSeries = [10, 20, 25, 45];

  return (
    <>
      <AddMoneyModal
        modalOpen={addMoneyModalOpen}
        setModalOpen={setAddMoneyModalOpen}
      />
      <Card sx={{ p: 3 }}>
        <Grid spacing={3} container>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                pb: 3
              }}
              variant="h4"
            >
              {t('Balance')}
            </Typography>
            <Box>
              <Typography variant="h1" gutterBottom>
                {`₹${MoneyService.fromServer(balance)}`}
              </Typography>
              <Box
                display="flex"
                sx={{
                  py: 4
                }}
                alignItems="center"
              >
                {isGain ? (
                  <AvatarSuccess
                    sx={{
                      mr: 2
                    }}
                    variant="rounded"
                  >
                    <TrendingUp />
                  </AvatarSuccess>
                ) : (
                  <AvatarError
                    sx={{
                      mr: 2
                    }}
                    variant="rounded"
                  >
                    <TrendingDown />
                  </AvatarError>
                )}
                <Box>
                  <Typography variant="h4">
                    {' '}
                    {`${isGain ? '+' : '-'} ₹${MoneyService.fromServer(
                      deltaBalance,
                      true
                    )}`}
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    {t('In Date Range')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid sm={12} lg={6} item>
            <Button
              onClick={() => setAddMoneyModalOpen(true)}
              fullWidth
              variant="contained"
            >
              {t('Add Money')}
            </Button>
          </Grid>
          {/* <Grid sm={12} lg={6} item>
          <Button fullWidth variant="contained">
          {t('Receive')}
          </Button>
      </Grid> */}
        </Grid>
      </Card>
    </>
  );
}

export default WalletBalance;
