const AesEncryption = require('aes-encryption');

const AES_SECRET_KEY =
  '7e5432f8f34fc4972cafbb1fdececc2e72cfdcece09d686e153b205731df683b';

const aes = new AesEncryption();

aes.setSecretKey(AES_SECRET_KEY);

const encryptText = (text) => aes.encrypt(text);

export default encryptText;
