/* eslint-disable no-unused-vars */
import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// import { Link as RouterLink, useLocation } from 'react-router-dom';
// import numeral from 'numeral';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Slide,
  Divider,
  //   Tooltip,
  //   IconButton,
  //   Link,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  Zoom,
  styled,
  IconButton,
  Tooltip,
  Popover,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon,
  MenuItem,
  Menu
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

import { useTranslation } from 'react-i18next';
// import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
// import Label from 'src/components/Label';
// import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
// import Text from 'src/components/Text';
// import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import checkActionAllowed from 'src/config/AccessControl/checkActionAllowed';
import useAuth from 'src/hooks/useAuth';
import Action from 'src/config/models/Action';
import handleExportPdf from 'src/utils/pdfDownloader';
import handleExportCSV from 'src/utils/csvDownloader';
import FileCopyIcon from '@mui/icons-material/FileCopyRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';

import BulkActions from './BulkActions';
import getTableData from './getTableData';
import SuspenseLoader from '../SuspenseLoader';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (items, query, properties) => {
  return items?.filter((item) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      properties?.forEach((property) => {
        const data = _.get(item, property, '');

        const dataLowercase =
          typeof data === 'string'
            ? data.toLowerCase()
            : JSON.stringify(data)
                ?.replace(/{|}|"|'/g, '')
                .toLowerCase();

        if (dataLowercase.includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (items, page, limit) => {
  return items?.slice(page * limit, page * limit + limit);
};

const BrandTable = ({
  tableConfig,
  tableData,
  filterables,
  searchPlaceholder,
  noResultsMessage,
  loading,
  onEditAction,
  onDeleteAction,
  onAssociateAction,
  actions,
  customKey,
  bulkActions,
  onDownload,
  csvFileName,
  csvFormat,
  onPDFdownload,
  showBackButton,
  handleInitialState
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  //   const location = useLocation();

  const { user, permissions } = useAuth();

  const isAdmin = user.role === 'SUPER_ADMIN';

  const allowedActions =
    actions?.filter(
      (action) => isAdmin || checkActionAllowed(action, permissions)
    ) ?? [];

  console.log({ allowedActions, actions, permissions });

  const editEnabled = onEditAction && onEditAction instanceof Action;
  // &&
  // (isAdmin || checkActionAllowed(onEditAction, permissions));
  const deleteEnabled = onDeleteAction && onDeleteAction instanceof Action;
  // &&
  // (isAdmin || checkActionAllowed(onDeleteAction, permissions));

  const bulkActionsEnabled = bulkActions?.length > 0;

  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const exportMenuOpen = Boolean(exportAnchorEl);

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportAnchorEl(null);
  };

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [actionsOpen, setActionsOpen] = useState(null);
  const [popover, setPopover] = useState(null);

  const handleMenuItemClick = () => {
    handleCloseExport();
    if (onPDFdownload) {
      onPDFdownload();
    } else {
      handleExportPdf(filteredData, csvFormat, csvFileName);
    }
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAllItems = (event) => {
    setSelectedItems(
      event.target.checked ? tableData?.map((_data) => _data.id) : []
    );
  };

  const handleSelectOneItem = (event, dataId) => {
    if (!selectedItems.includes(dataId)) {
      setSelectedItems((prevSelected) => [...prevSelected, dataId]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== dataId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredData = applyFilters(tableData, query, filterables);
  const paginatedData = applyPagination(filteredData, page, limit);
  const selectedBulkActions = selectedItems?.length > 0;
  const selectedSomeItems =
    selectedItems?.length > 0 && selectedItems?.length < filteredData?.length;
  const selectedAllItems = selectedItems?.length === filteredData?.length;
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  //   const handleConfirmDelete = () => {
  //     setOpenConfirmDelete(true);
  //   };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('You successfully deleted the item'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const closeActionsMenu = () => {
    setActionsOpen(null);
    setPopover(null);
  };
  const createActionMenuHandler = (id) => (clickHandler) => () => {
    clickHandler?.(id);
    closeActionsMenu();
  };
  const createOtherActionHandler = (data) => (clickHandler) => () => {
    clickHandler?.(data);
    closeActionsMenu();
  };
  const openActionsMenu = (id) => (e) => {
    setActionsOpen(e.currentTarget);
    setPopover(id);
  };

  const [headers, displayData] = getTableData(tableConfig, paginatedData);

  return (
    <>
      <Card>
        <Box display="flex" alignItems="center">
          {Boolean(bulkActionsEnabled && selectedBulkActions) && (
            <Box flex={1} p={2}>
              <BulkActions selectedItems={selectedItems} />
            </Box>
          )}
          {!(bulkActionsEnabled && selectedBulkActions) && (
            <Box
              flex={1}
              p={2}
              display={{ xs: 'block', md: 'flex' }}
              alignItems="center"
              // justifyContent="space-between"
            >
              {showBackButton && (
                <Box sx={{ mr: 3 }}>
                  <Button
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      handleInitialState();
                    }}
                  >
                    Back
                  </Button>
                </Box>
              )}

              <Box
                sx={{
                  mb: { xs: 2, md: 0 }
                }}
              >
                {Boolean(filterables?.length) && (
                  <TextField
                    size="small"
                    fullWidth={mobile}
                    onChange={handleQueryChange}
                    value={query}
                    style={{ width: '150%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchTwoToneIcon />
                        </InputAdornment>
                      )
                    }}
                    placeholder={t(searchPlaceholder)}
                  />
                )}
              </Box>
            </Box>
          )}

          {onDownload && (
            <Box
              sx={{
                p: 2
              }}
            >
              <Button
                id="demo-customized-button"
                aria-controls={
                  exportMenuOpen ? 'demo-customized-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={exportMenuOpen ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={handleExportClick}
                disabled={!filteredData?.length}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Export
              </Button>
              <Menu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={exportAnchorEl}
                open={exportMenuOpen}
                onClose={handleCloseExport}
                PaperProps={{
                  style: {
                    minWidth: '20ch'
                  }
                }}
              >
                <MenuItem onClick={handleMenuItemClick}>
                  <ListItemIcon>
                    <PictureAsPdfRoundedIcon />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    PDF
                  </Typography>
                </MenuItem>

                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={() => {
                    handleCloseExport();
                    handleExportCSV(filteredData, csvFormat, csvFileName);
                  }}
                >
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    CSV
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
          {/* {onDownload && (
            <>
              <Box
                sx={{
                  mb: { xs: 2, md: 0 },
                  ml: 'auto'
                }}
              >
                <Button
                  onClick={() =>
                    handleExportCSV(filteredData, csvFormat, csvFileName)
                  }
                >
                  Export CSV
                </Button>
              </Box>
              <Box
                sx={{
                  mb: { xs: 2, md: 0 },
                  ml: 'auto'
                }}
              >
                <Button
                  onClick={() =>
                    handleExportPdf(filteredData, csvFormat, csvFileName)
                  }
                >
                  Export PDF
                </Button>
              </Box>
            </>
          )} */}
        </Box>
        <Divider />
        {loading && <SuspenseLoader />}
        {Boolean(displayData?.length === 0 && !loading) && (
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {t(noResultsMessage)}
          </Typography>
        )}

        {Boolean(displayData?.length !== 0 && !loading) && (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {bulkActionsEnabled && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAllItems}
                          indeterminate={selectedSomeItems}
                          onChange={handleSelectAllItems}
                        />
                      </TableCell>
                    )}
                    {headers?.map((header) => (
                      <TableCell>{header}</TableCell>
                    ))}
                    {Boolean(
                      editEnabled || deleteEnabled || allowedActions?.length
                    ) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayData?.map((row, rowIdx) => {
                    const isItemSelected = selectedItems.includes(
                      paginatedData[rowIdx].id ||
                        paginatedData[rowIdx][customKey]
                    );
                    return (
                      <TableRow
                        hover
                        key={`row-${
                          paginatedData[rowIdx].id ||
                          paginatedData[rowIdx][customKey]
                        }`}
                        selected={isItemSelected}
                      >
                        {bulkActionsEnabled && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleSelectOneItem(
                                  event,
                                  paginatedData[rowIdx].id
                                )
                              }
                              value={isItemSelected}
                            />
                          </TableCell>
                        )}
                        {row?.map((item) => (
                          <TableCell>{item}</TableCell>
                        ))}
                        {Boolean(
                          editEnabled || deleteEnabled || allowedActions?.length
                        ) && (
                          <TableCell
                            key={`actions-cell-${
                              paginatedData[rowIdx].id ||
                              paginatedData[rowIdx][customKey]
                            }`}
                          >
                            <Tooltip
                              title="Open Actions"
                              key={`actions-tooltip-${
                                paginatedData[rowIdx].id ||
                                paginatedData[rowIdx][customKey]
                              }`}
                            >
                              <IconButton
                                color="primary"
                                onClick={openActionsMenu(
                                  paginatedData[rowIdx].id ||
                                    paginatedData[rowIdx][customKey]
                                )}
                                aria-describedby={`actions-popover-${
                                  paginatedData[rowIdx].id ||
                                  paginatedData[rowIdx][customKey]
                                }`}
                                id={`actions-button-${
                                  paginatedData[rowIdx].id ||
                                  paginatedData[rowIdx][customKey]
                                }`}
                                key={`actions-button-${
                                  paginatedData[rowIdx].id ||
                                  paginatedData[rowIdx][customKey]
                                }`}
                              >
                                <MoreVertRoundedIcon />
                              </IconButton>
                            </Tooltip>

                            {Boolean(
                              editEnabled ||
                                deleteEnabled ||
                                allowedActions?.length
                            ) && (
                              <Popover
                                key={`actions-popover-${
                                  paginatedData[rowIdx].id ||
                                  paginatedData[rowIdx][customKey]
                                }`}
                                id={`actions-popover-${
                                  paginatedData[rowIdx].id ||
                                  paginatedData[rowIdx][customKey]
                                }`}
                                open={
                                  popover ===
                                  (paginatedData[rowIdx].id ||
                                    paginatedData[rowIdx][customKey])
                                }
                                disableScrollLock
                                anchorEl={actionsOpen}
                                onClose={closeActionsMenu}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <List
                                  sx={{
                                    p: 1
                                  }}
                                  component="nav"
                                >
                                  {editEnabled && (
                                    <ListItemButton
                                      button
                                      onClick={createActionMenuHandler(
                                        paginatedData[rowIdx].id ||
                                          paginatedData[rowIdx][customKey]
                                      )(onEditAction.run)}
                                    >
                                      <ListItemIcon>
                                        <EditRoundedIcon
                                          color="warning"
                                          fontSize="small"
                                        />
                                      </ListItemIcon>

                                      <ListItemText primary={t('Edit')} />
                                    </ListItemButton>
                                  )}
                                  {deleteEnabled && (
                                    <ListItemButton
                                      button
                                      onClick={createActionMenuHandler(
                                        paginatedData[rowIdx].id ||
                                          paginatedData[rowIdx][customKey]
                                      )(onDeleteAction.run)}
                                    >
                                      <ListItemIcon>
                                        <DeleteRoundedIcon
                                          color="error"
                                          fontSize="small"
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary={t('Delete')} />
                                    </ListItemButton>
                                  )}

                                  {Boolean(
                                    (editEnabled || deleteEnabled) &&
                                      allowedActions?.length
                                  ) && <Divider />}

                                  {allowedActions?.map((action) => (
                                    <>
                                      {!action.meta.shouldHide?.(
                                        paginatedData[rowIdx]
                                      ) && (
                                        <ListItemButton
                                          onClick={createOtherActionHandler(
                                            paginatedData[rowIdx]
                                          )(action.run)}
                                          disabled={action.meta?.shouldDisable?.(
                                            paginatedData[rowIdx]
                                          )}
                                        >
                                          {action.meta.icon && (
                                            <ListItemIcon>
                                              {action.meta.icon}
                                            </ListItemIcon>
                                          )}
                                          <ListItemText
                                            primary={
                                              action.label ?? action?.label
                                            }
                                          />
                                        </ListItemButton>
                                      )}
                                    </>
                                  ))}
                                </List>
                              </Popover>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredData?.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          </>
        )}
      </Card>
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Do you really want to delete this product')}?
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            {t("You won't be able to revert after deletion")}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

BrandTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  tableConfig: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

BrandTable.defaultProps = {
  products: [],
  tableConfig: [],
  loading: false
};

export default BrandTable;
