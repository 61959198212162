import { mock } from 'src/utils/axios';

let vehicleUsage = [
  {
    date: "8th May' 22",
    route: 'Ward 025 Tipper route',
    vehicleRegNo: 'C8/W51-A/RJ01GB7858',
    distance: 5,
    ward: 'Ward 025',
    circle: 'Circle 08',
    driverName: 'Jagat',
    driverContact: '9937789586',
    routeCoveragePercent: '96%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 020 Tipper route',
    vehicleRegNo: 'C9/W72-B/RJ01GB7902',
    distance: 8.2,
    ward: 'Ward 020',
    circle: 'Circle 03',
    driverName: 'Ramanujan',
    driverContact: '7657789516',
    routeCoveragePercent: '97%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 025 Tipper route',
    vehicleRegNo: 'C4/W22-A/RJ01GB7909',
    distance: 9.8,
    ward: 'Ward 025',
    circle: 'Circle 013',
    driverName: 'Harendra',
    driverContact: '6347959535',
    routeCoveragePercent: '79%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 046 Tipper route',
    vehicleRegNo: 'C8/W55-B/RJ01GB7883',
    distance: 5.2,
    ward: 'Ward 046',
    circle: 'Circle 011',
    driverName: 'Ganga Ram',
    driverContact: '7237616573',
    routeCoveragePercent: '75%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 030 Tipper route',
    vehicleRegNo: 'C10/W74-A/RJ01GB7870',
    distance: 6.6,
    ward: 'Ward 030',
    circle: 'Circle 012',
    driverName: 'Gurupreet',
    driverContact: '7826542559',
    routeCoveragePercent: '82%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 035 Tipper route',
    vehicleRegNo: 'C8/W54-A/RJ01GB7894',
    distance: 7.5,
    ward: 'Ward 035',
    circle: 'Circle 013',
    driverName: 'Devilal',
    driverContact: '7264651607',
    routeCoveragePercent: '84%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 050 Tipper route',
    vehicleRegNo: 'C1/W2-A/RJ01GB7881',
    distance: 14.5,
    ward: 'Ward 050',
    circle: 'Circle 014',
    driverName: 'Damodhara',
    driverContact: '9816750609',
    routeCoveragePercent: '79%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 02 Tipper route',
    vehicleRegNo: 'C10/W77-B/RJ01GB7867',
    distance: 14.1,
    ward: 'Ward 02',
    circle: 'Circle 05',
    driverName: 'Arjun',
    driverContact: '8831649443',
    routeCoveragePercent: '93%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 015 Tipper route',
    vehicleRegNo: 'C5/W32-B/RJ01GB7891',
    distance: 12.4,
    ward: 'Ward 015',
    circle: 'Circle 01',
    driverName: 'Bharat',
    driverContact: '7067178616',
    routeCoveragePercent: '96%'
  },
  {
    date: "8th May' 22",
    route: 'Ward 042 Tipper route',
    vehicleRegNo: 'C8/W60-B/RJ01GB6878',
    distance: 9.7,
    ward: 'Ward 042',
    circle: 'Circle 010',
    driverName: 'Amrinder',
    driverContact: '7837659586',
    routeCoveragePercent: '98%'
  }
];

mock.onGet('/api/vehicle-usage').reply(() => {
  return [200, { vehicleUsage }];
});
