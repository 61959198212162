import { useEffect } from 'react';
import { DatePicker } from '@mui/lab';
import { Grid, TextField, Box } from '@mui/material';

export function formikDateSetter(name, setter) {
  return (value) => setter?.(name, new Date(value).toISOString());
}

function BrandDatePicker({
  xs,
  md,
  placeholder,
  value,
  onChange,
  label,
  name,
  maxDate,
  minDate
}) {
  useEffect(() => {
    formikDateSetter(name, value && new Date(value));
  }, []);

  return (
    <Grid item xs={xs || 12} md={md || 6}>
      <Box pb={1}>
        <b>{label}:</b>
      </Box>
      <DatePicker
        value={value && new Date(value)}
        onChange={formikDateSetter(name, onChange)}
        renderInput={(params) => (
          <TextField fullWidth placeholder={placeholder} {...params} />
        )}
        maxDate={maxDate ?? new Date()}
        minDate={minDate}
        inputFormat="dd/MM/yyyy"
      />
    </Grid>
  );
}

export default BrandDatePicker;
