/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import { useLocation, useParams } from 'react-router';
import VehicleService from 'src/services/Vehicle.service';
import QRCode from 'qrcode';
import DateTimeService from 'src/utils/DateTimeService';
import OrganisationService from 'src/services/Organisation.service';
import useAuth from 'src/hooks/useAuth';
import logo from '../../assets/images/TraxSmart.png';
import ComponentLoader from '../ComponentLoader';
import CertificateSectionLayout from './CertificateSectionLayout';
import certificateDataFormat from './CertificateData';

const CertificateLayout = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const currentURL = window.location.href;

  const [qrCodeDataUrl, setQrCodeDataUrl] = useState(null);
  const [certificateData, setCertificateData] = useState(null);
  const [certificateLayoutData, setCertificateLayoutData] = useState(null);
  const [organisationLogo, setOrganisationLogo] = useState(logo);
  const [loading, setLoading] = useState(false);

  Font.register({
    family: 'BoldOpenSans',
    src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf'
  });

  const getCertificate = async () => {
    setLoading(true);
    try {
      const {
        data: [data]
      } = await VehicleService.get({
        imei: id,
        hydrate: 'device,owner,vendor'
      });
      setCertificateData(data);
      setCertificateLayoutData(certificateDataFormat(data));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getOrganisationLogo = async () => {
    const {
      data: [organisationData]
    } = await OrganisationService.get({
      _id: user?.organisation
    });
    setOrganisationLogo(organisationData?.attributes?.logo || logo);
  };

  useEffect(() => {
    getCertificate();
    getOrganisationLogo();
    QRCode.toDataURL(currentURL, (err, url) => {
      if (err) throw err;
      setQrCodeDataUrl(url);
    });
  }, [id]);

  const styles = StyleSheet.create({
    vehicleTable: {
      display: 'table',
      width: 'auto',
      borderStyle: 'none',
      fontSize: '8px',
      flexGrow: 7
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'none',
      fontSize: '8px'
    },
    row: {
      flexDirection: 'row'
    },
    cell: {
      flexGrow: 1,
      padding: 5,
      width: '25%',
      overflow: 'hidden'
    },
    header: {
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold',
      marginBottom: '3px'
    },
    paper: {
      margin: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    customImage: {
      width: '90px',
      height: '90px',
      objectFit: 'contain',
      marginBottom: '5px'
    },

    grid: {
      borderBottom: '1px solid grey',
      margin: '5px 0',
      padding: '10px',
      display: 'flex',
      flexDirection: 'row',
      width: '95%',
      flexWrap: 'wrap',
      gap: '20px'
    },

    textSection: {
      marginLeft: '10px'
    },
    heading: {
      fontSize: '12px',
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold',
      marginBottom: '10px'
    },
    title: {
      fontFamily: 'BoldOpenSans',
      fontWeight: 'bold'
    },
    qrCode: {
      textAlign: 'center'
    },
    footerText: {
      fontSize: '8px',
      margin: '5px 10px'
    },
    headerLayout: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      justifyItems: 'space-between',
      alignItems: 'center'
    },
    childComponent: {
      marginLeft: '10px'
    }
  });

  const MyDocument = () => {
    const [qrCodeDataUrl, setQrCodeDataUrl] = useState(null);

    const PdfDataLayout = ({ data }) => {
      return (
        <View style={styles.table}>
          {data
            ?.filter((item) => item?.accessor !== (null || undefined))
            .reduce((result, item, index) => {
              if (index % 4 === 0) result.push([]);
              result[result.length - 1].push(item);
              return result;
            }, [])
            .map((chunk) => (
              <View style={styles.row}>
                {chunk.map((item) => (
                  <View style={styles.cell}>
                    <Text style={styles.header}>{item?.header}</Text>
                    <Text>{item?.accessor}</Text>
                  </View>
                ))}
                {Array(4 - chunk.length)
                  .fill(null)
                  .map(() => (
                    <View style={styles.cell} />
                  ))}
              </View>
            ))}
        </View>
      );
    };

    const PdfSectionLayout = ({ _certificateData }) => {
      return (
        <View style={styles.grid}>
          <Text style={styles.heading}>{_certificateData?.header}</Text>
          <PdfDataLayout data={_certificateData?.data} />
        </View>
      );
    };

    useEffect(() => {
      getOrganisationLogo();
      QRCode.toDataURL(currentURL, (err, url) => {
        if (err) throw err;
        setQrCodeDataUrl(url);
      });
    }, []);

    return (
      <Document>
        <Page>
          <View style={styles.paper}>
            <View style={styles.headerLayout}>
              <View style={styles.childComponent}>
                <Image
                  style={styles.customImage}
                  src={organisationLogo}
                  alt="Logo"
                />
              </View>
              <View style={styles.childComponent}>
                <Text style={styles.title}>VLTD Fitment Certificate</Text>
              </View>
              <View style={styles.qrCode}>
                <Image style={styles.customImage} src={qrCodeDataUrl} />
              </View>
            </View>

            {certificateLayoutData?.map((item) => (
              <PdfSectionLayout _certificateData={item} />
            ))}
            <Text style={styles.footerText}>
              Disclaimer: We hereby acknowledge the installation of a
              GPS/VTS/VLTD device, provided by M/s. Vasp Infotech with IMEI No.
              {certificateData?.imei}, on our vehicle registered under number
              {certificateData?.registrationNumber}. Subsequent to the
              installation, we conducted a thorough assessment of the vehicle's
              performance and are pleased to affirm that the device is operating
              in compliance with the standards stipulated in AIS140 by
              ARAI/ICAT. Our satisfaction extends across all operational aspects
              of the unit. We wish to assert our commitment to refrain from
              initiating any disputes or legal claims against M/s. Vasp Infotech
              in the event that the aforementioned seals, device, and harness
              display any signs of tampering or breakage within the 12-month
              warranty period from the date of installation. It is imperative to
              note that any and all disputes arising shall be subjected solely
              to Kolkata jurisdiction. only *
            </Text>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <>
      {loading && <ComponentLoader />}
      {!loading && certificateData && (
        <Paper
          sx={{
            m: '2em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
          elevation={1}
        >
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ ml: '1em' }}>
              <img
                style={{
                  maxWidth: '12em',
                  maxHeight: '12em',
                  objectFit: 'contain'
                }}
                src={organisationLogo}
                alt="Logo"
              />
            </Box>
            <Typography variant="h3">VLTD Fitment Certificate</Typography>
            <Box>
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
                src={qrCodeDataUrl}
                alt="qr code"
              />
            </Box>
          </Stack>

          {certificateLayoutData?.map((item) => (
            <>
              <Grid
                container
                sx={{
                  borderBottom: '2px solid grey',
                  my: '1em',
                  overflow: 'hidden',
                  maxWidth: '95%',
                  p: '2em'
                }}
                spacing={2}
              >
                <Grid md={12}>
                  <Typography sx={{ mb: '0.5em' }} variant="h4">
                    {item?.header}
                  </Typography>
                </Grid>
                <Grid md={12}>
                  <CertificateSectionLayout data={item?.data} />
                </Grid>
              </Grid>
            </>
          ))}
          <Typography sx={{ mx: '2em', mt: '1em' }}>
            Disclaimer: We hereby acknowledge the installation of a GPS/VTS/VLTD
            device, provided by M/s. Vasp Infotech with IMEI No.
            {certificateData?.imei}, on our vehicle registered under number
            {certificateData?.registrationNumber}. Subsequent to the
            installation, we conducted a thorough assessment of the vehicle's
            performance and are pleased to affirm that the device is operating
            in compliance with the standards stipulated in AIS140 by ARAI/ICAT.
            Our satisfaction extends across all operational aspects of the unit.
            We wish to assert our commitment to refrain from initiating any
            disputes or legal claims against M/s. Vasp Infotech in the event
            that the aforementioned seals, device, and harness display any signs
            of tampering or breakage within the 12-month warranty period from
            the date of installation. It is imperative to note that any and all
            disputes arising shall be subjected solely to Kolkata jurisdiction.
          </Typography>
          <PDFDownloadLink
            document={<MyDocument />}
            fileName={`${
              certificateData?.registrationNumber
            }-VLTD_Certificate-${DateTimeService.getDateString(
              certificateData?.device?.fitment?.date
            )}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              console.log('error', error);
              return (
                <Button sx={{ my: '2em' }} variant="contained">
                  {loading ? 'Loading document...' : 'Print'}
                </Button>
              );
            }}
          </PDFDownloadLink>
        </Paper>
      )}
    </>
  );
};

export default CertificateLayout;
