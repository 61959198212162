import { createSlice } from '@reduxjs/toolkit';
import OrganisationService from 'src/services/Organisation.service';

const initialState = {
  tradeName: '',
  brandName: 'TRAXSMART',
  contact: '',
  email: '',
  address: {
    country: 'INDIA',
    landmark: '',
    pin: 700072,
    city: 'Kolkata',
    state: 'WB'
  },
  attributes: {
    logo: '/static/images/logo/traxsmart.png'
  },
  gst: '',
  isOnboarded: true,
  deleted: false,
  id: '65426bc91d114bf4340a7c33'
};

const slice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    getOrganisation(state, action) {
      const {
        tradeName,
        brandName,
        contact,
        email,
        address,
        attributes,
        gst,
        isOnboarded,
        deleted,
        id
      } = action.payload;

      state.tradeName = tradeName;
      state.brandName = brandName;
      state.contact = contact;
      state.email = email;
      state.address = address;
      state.attributes = attributes;
      state.gst = gst;
      state.isOnboarded = isOnboarded;
      state.deleted = deleted;
      state.id = id;
    }
  }
});

export const reducer = slice.reducer;

export const getOrganisation = () => async (dispatch) => {
  const {
    data: [organisationData]
  } = await OrganisationService.get({});

  dispatch(slice.actions.getOrganisation(organisationData));
};

export default slice;
