import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const data = [
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Rahul Sharma',
    driverContact: '+91 9876543210',
    driverId: 'EMP001',
    jobName: 'Delivery to Warehouse',
    startTime: '2023-07-25T08:00:00Z',
    endTime: '2023-07-25T10:30:00Z',
    status: 'Finished',
    coverage: 95
  },
  {
    vehicleName: 'Hyundai Creta',
    vehicleId: '615e8b3c8e39052a9e6c3542',
    vehicleNo: 'GJ05CD5678',
    driverName: 'Amit Patel',
    driverContact: '+91 8765432109',
    driverId: 'EMP002',
    jobName: 'Employee Pick-up',
    startTime: '2023-07-25T09:15:00Z',
    endTime: null,
    status: 'Started',
    coverage: 60
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleId: '615e8b3c8e39052a9e6c3543',
    vehicleNo: 'DL07EF9012',
    driverName: 'Sandeep Singh',
    driverContact: '+91 7654321098',
    driverId: 'EMP003',
    jobName: 'Delivery to Customer',
    startTime: '2023-07-25T11:30:00Z',
    endTime: null,
    status: 'Started',
    coverage: 75
  },
  {
    vehicleName: 'Honda City',
    vehicleId: '615e8b3c8e39052a9e6c3544',
    vehicleNo: 'MH04IJ7890',
    driverName: 'Rajesh Gupta',
    driverContact: '+91 6543210987',
    driverId: 'EMP004',
    jobName: 'Loading Goods',
    startTime: '2023-07-25T13:00:00Z',
    endTime: '2023-07-25T14:45:00Z',
    status: 'Finished',
    coverage: 100
  },
  {
    vehicleName: 'Ford EcoSport',
    vehicleId: '615e8b3c8e39052a9e6c3545',
    vehicleNo: 'RJ14MN4567',
    driverName: 'Ankur Verma',
    driverContact: '+91 5432109876',
    driverId: 'EMP005',
    jobName: 'Delivery to Retail Store',
    startTime: '2023-07-25T15:30:00Z',
    endTime: null,
    status: 'Started',
    coverage: 80
  },
  {
    vehicleName: 'Mahindra XUV500',
    vehicleId: '615e8b3c8e39052a9e6c3546',
    vehicleNo: 'UP32KL2345',
    driverName: 'Arun Kumar',
    driverContact: '+91 4321098765',
    driverId: 'EMP006',
    jobName: 'Employee Drop-off',
    startTime: '2023-07-25T16:45:00Z',
    endTime: '2023-07-25T18:00:00Z',
    status: 'Finished',
    coverage: 90
  },
  {
    vehicleName: 'Volkswagen Polo',
    vehicleId: '615e8b3c8e39052a9e6c3547',
    vehicleNo: 'HR26OP5678',
    driverName: 'Nikhil Sharma',
    driverContact: '+91 3210987654',
    driverId: 'EMP007',
    jobName: 'Unloading Goods',
    startTime: '2023-07-25T19:30:00Z',
    endTime: null,
    status: 'Started',
    coverage: 70
  },
  {
    vehicleName: 'Tata Nexon',
    vehicleId: '615e8b3c8e39052a9e6c3548',
    vehicleNo: 'TN01QR7890',
    driverName: 'Vikas Singh',
    driverContact: '+91 2109876543',
    driverId: 'EMP008',
    jobName: 'Delivery to Warehouse',
    startTime: '2023-07-25T20:15:00Z',
    endTime: null,
    status: 'Started',
    coverage: 50
  },
  {
    vehicleName: 'Renault Kwid',
    vehicleId: '615e8b3c8e39052a9e6c3549',
    vehicleNo: 'DL09ST1234',
    driverName: 'Gaurav Patel',
    driverContact: '+91 1098765432',
    driverId: 'EMP009',
    jobName: 'Employee Pick-up',
    startTime: '2023-07-25T21:30:00Z',
    endTime: null,
    status: 'Started',
    coverage: 65
  },
  {
    vehicleName: 'Chevrolet Beat',
    vehicleId: '615e8b3c8e39052a9e6c3550',
    vehicleNo: 'MH02QR5678',
    driverName: 'Rakesh Gupta',
    driverContact: '+91 9876543210',
    driverId: 'EMP010',
    jobName: 'Loading Goods',
    startTime: '2023-07-25T22:45:00Z',
    endTime: '2023-07-25T23:30:00Z',
    status: 'Finished',
    coverage: 95
  }
];

mock.onGet('/api/vehicle-jobs').reply(() => {
  return [200, data];
});
