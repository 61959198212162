const {
  LOGIN_URL,
  GET_LOGGED_IN_URL,
  REFRESH_TOKEN_URL,
  LOGOUT_URL,
  FORGET_PASSWORD_URL,
  RESET_PASSWORD_URL
} = require('src/config/api.config');
const { default: AppResponse } = require('src/config/models/AppResponse.model');
const { doPost, doGet } = require('src/utils/apiCallers');

export default class AuthService {
  static async login(username, password) {
    try {
      const payload = { username, password };

      const loginRes = await doPost(LOGIN_URL, payload);
      console.log(loginRes);

      return new AppResponse(loginRes.success, loginRes.message, loginRes.data);
    } catch (err) {
      console.error(err);

      return new AppResponse(false, err.message ?? err, null);
    }
  }

  static async getLoggedInUser(accessToken) {
    try {
      const loggedInRes = await doGet(`${GET_LOGGED_IN_URL}/${accessToken}`);
      return loggedInRes?.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static async refreshTokens(refreshToken) {
    try {
      const refreshRes = await doPost(REFRESH_TOKEN_URL, { refreshToken });
      return new AppResponse(
        refreshRes.success,
        refreshRes.message,
        refreshRes.data
      );
    } catch (err) {
      return new AppResponse(false, err.message ?? err, null);
    }
  }

  static async forgetPassword(username) {
    try {
      const res = await doPost(FORGET_PASSWORD_URL, { username });
      return new AppResponse(res.success, res.message, res.data);
    } catch (err) {
      return new AppResponse(false, err.message ?? err, null);
    }
  }
  static async resetPasswordWithOTP(otp, otpToken, password) {
    try {
      const res = await doPost(`${RESET_PASSWORD_URL}?otp=${otp}`, {
        otpToken,
        password
      });
      return new AppResponse(res.success, res.message, res.data);
    } catch (err) {
      return new AppResponse(false, err.message ?? err, null);
    }
  }

  static async resetPasswordWithToken(token, password) {
    try {
      const res = await doPost(`${RESET_PASSWORD_URL}?token=${token}`, {
        password
      });
      return new AppResponse(res.success, res.message, res.data);
    } catch (err) {
      return new AppResponse(false, err.message ?? err, null);
    }
  }

  static async logout() {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      await doPost(LOGOUT_URL, { refreshToken });
    } catch (err) {
      console.error(err);
    }
  }
}
