import { ORGANISATION_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class OrganisationService extends ApiService {
  constructor() {
    super(ORGANISATION_URL);
  }
}

export default new OrganisationService();
