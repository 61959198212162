export default class ModuleBundle {
  name = '';
  modules = [];

  constructor(name, modules) {
    if (!name || !modules)
      throw new Error(
        `Failed to register module bundle for ${
          name || 'Unkown'
        }. name, list of modules are required.`
      );

    this.name = name;
    this.modules = modules;
  }
}
