import isProd from './isProd';

const LOCAL_TESTING_URL = 'http://127.0.0.1:4040/v1';
const STAGIN_URL = 'https://vltd-api-staging.traxsmart.in/v1';
const PROD_URL = 'https://cloud-api.traxsmart.in/v1';

// const isDev = true;

const BASE_URL = isProd ? PROD_URL : STAGIN_URL;
// const BASE_URL = LOCAL_TESTING_URL;
// const BASE_URL = 'https://vltd-api-staging.traxsmart.in/v1';
// const BASE_URL = 'https://ascl-ps.traxsmart.in/api';

// AUTH URLS
export const AUTH_URL = `${BASE_URL}/auth`;
export const LOGIN_URL = `${AUTH_URL}/login`;
export const REFRESH_TOKEN_URL = `${AUTH_URL}/refresh-tokens`;
export const REGISTER_URL = `${AUTH_URL}/register`;
export const LOGOUT_URL = `${AUTH_URL}/logout`;
export const GET_LOGGED_IN_URL = `${AUTH_URL}/logged-in-user`;
export const FORGET_PASSWORD_URL = `${AUTH_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${AUTH_URL}/reset-password`;

// APP URLS

// user urls
export const USER_URL = `${BASE_URL}/users`;
export const RAW_LOGS = `${BASE_URL}/raw-logs`;
export const ROLE_URL = `${BASE_URL}/roles`;
export const DESIGNATION_URL = `${BASE_URL}/designations`;
export const ORGANISATION_URL = `${BASE_URL}/organisation`;

export const FIRMWARE_URL = `${BASE_URL}/firmware`;

// survey end points
export const ADMIN_DIVISION_URL = `${BASE_URL}/admin-divisions`;
export const ASSET_URL = `${BASE_URL}/asset`;
export const IDENTIFIER_URL = `${BASE_URL}/identifier`;

export const ADDRESS_URL = `${BASE_URL}/locations`;
export const GEOFENCE_URL = `${BASE_URL}/geofences`;
export const VEHICLE_URL = `${BASE_URL}/vehicle`;
export const DEVICE_URL = `${BASE_URL}/device`;
export const VEHICLE_STATUS_URL = `${BASE_URL}/vehicle/statuses`;
export const POSITION_URL = `${BASE_URL}/vehicle/position/track`;
export const ROUTE_URL = `${BASE_URL}/routes`;
export const CERTIFICATE_URL = `${BASE_URL}/certificate`;
export const REPORT_ALERT = `${BASE_URL}/report/alert`;
export const VEHICLE_TIMELINE_URL = `${BASE_URL}/vehicle/trips/timeline`;
export const VEHICLE_GEOFENCE_ASSOCIATION_URL = `${BASE_URL}/association`;
export const GROUP_URL = `${BASE_URL}/group`;
export const SHARED_PLAYBACK_URL = `${BASE_URL}/share-playback`;
export const POI_URL = `${BASE_URL}/POI`;

// Item
export const ITEM_URL = `${BASE_URL}/item`;
export const ACTIVATION_URL = `${BASE_URL}/activation`;

// Reports
export const PATROL_DUTY_SUMMARY = `${BASE_URL}/report/patrol-duty/summary`;
