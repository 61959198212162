export default class Module {
  name = '';
  path = '';
  pathDef;
  id = '';
  Component;
  isSidebarItem = false;
  actions = {};

  constructor({ name, path, pathDef, id, Component, actions }) {
    if (!name || !id || (!path && !Component))
      throw new Error(
        'Failed to register module. name, id and path or Component are required.'
      );

    this.id = id;
    this.name = name;
    this.path = path;
    this.pathDef = pathDef ?? path;
    this.basePath = this.pathDef.split('/')[0];
    this.subPathDef = `/${this.pathDef.split('/').splice(1).join('/')}`;
    this.Component = Component;
    this.actions = actions ?? this.actions;
  }
}
