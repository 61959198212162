import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const GenerateCertificate = Loader(
  lazy(() => import('src/components/Certificate'))
);

const CertificateLayout = Loader(
  lazy(() => import('src/components/Certificate/CertificateLayout'))
);

const privateCertificateRoutes = [
  { path: '/', element: <GenerateCertificate /> }
];

const publicCertificateRoutes = [
  { path: '/view', element: <CertificateLayout /> }
];

export { privateCertificateRoutes, publicCertificateRoutes };
