import { createContext, useEffect, useReducer } from 'react';
import axios from 'src/utils/axios';
import PropTypes from 'prop-types';
import AuthService from 'src/services/Auth.service';
import { toast } from 'react-hot-toast';
import RoleService from 'src/services/Role.service';
import PermissionService from 'src/services/Permission.service';
import encryptText from 'src/config/aes.secret';
import isProd from 'src/config/isProd';

const sessionKey = 'kL6OHTH90yVmTJ23o3aE7xFhtOOOQL';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  permissions: []
};

const setSession = (
  accessToken,
  refreshToken,
  accessExpiresAt,
  refreshExpiresAt
) => {
  if (accessToken && refreshToken) {
    const session = {
      accessToken,
      refreshToken,
      accessExpiresAt,
      refreshExpiresAt
    };

    const sessionEncripted = btoa(JSON.stringify(session));
    localStorage.setItem(sessionKey, sessionEncripted);

    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(sessionKey);
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const session = localStorage.getItem(sessionKey);

        const { accessToken, refreshToken, accessExpiresAt, refreshExpiresAt } =
          JSON.parse(atob(session));

        const accessExpiryDateTime =
          accessExpiresAt && new Date(accessExpiresAt);

        const refreshExpiryDateTime =
          refreshExpiresAt && new Date(refreshExpiresAt);

        const allTokenAndExpiryExists =
          !!accessToken &&
          !!refreshToken &&
          !!accessExpiryDateTime &&
          !!refreshExpiryDateTime;

        const refreshTokenIsValid =
          Number(new Date()) < Number(refreshExpiryDateTime);

        if (!(allTokenAndExpiryExists && refreshTokenIsValid)) {
          alert('Session expired! Login again.');

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });

          return;
        }

        const accessTokenIsValid =
          Number(new Date()) < Number(accessExpiryDateTime);

        const loggedInUser = await AuthService.getLoggedInUser(accessToken);

        if (accessTokenIsValid && loggedInUser) {
          setSession(
            accessToken,
            refreshToken,
            accessExpiresAt,
            refreshExpiresAt
          );

          const role = await RoleService.getWithPermissions(loggedInUser.role);

          const { name: roleName, authScopes } = role.data;

          localStorage.setItem(
            'p',
            btoa(PermissionService.flattenObject(authScopes).toString())
          );

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: { ...loggedInUser, role: roleName },
              permissions: PermissionService.flattenObject(authScopes)
            }
          });
          return;
        }

        const tokenRes = await AuthService.refreshTokens(refreshToken);

        if (!tokenRes.success) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });

          return;
        }

        const {
          access: { token: newAccessToken, expires: newAccessExpiresAt },
          refresh: { token: newRefreshToken, expires: newRefreshExpiresAt }
        } = tokenRes.data;

        setSession(
          newAccessToken,
          newRefreshToken,
          newAccessExpiresAt,
          newRefreshExpiresAt
        );

        const reLoggedInUser = await AuthService.getLoggedInUser(
          newAccessToken
        );

        const role = await RoleService.getWithPermissions(loggedInUser.role);
        const { name: roleName, authScopes } = role.data;

        localStorage.setItem(
          'p',
          btoa(PermissionService.flattenObject(authScopes).toString())
        );

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: { ...reLoggedInUser, roleName },
            permissions: PermissionService.flattenObject(authScopes)
          }
        });
      } catch (err) {
        console.error(err);
        console.log('REDUX', { err });

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const TOAST_ID = 'loging_context';

    toast.loading('Logging in...', { id: TOAST_ID });

    const loginres = await AuthService.login(email, encryptText(password));

    if (!loginres.success) {
      toast.error(loginres.message ?? 'Login failed!', { id: TOAST_ID });
      return;
    }

    const {
      user,
      role,
      token: { access, refresh }
    } = loginres.data;

    const { authScopes, name: roleName } = role;

    user.role = roleName;

    const accessToken = access.token;
    const expiresAt = access.expires;
    const refreshToken = refresh.token;
    const refreshExpiresAt = refresh.expires;

    setSession(accessToken, refreshToken, expiresAt, refreshExpiresAt);
    toast.success('Successfully logged in', { id: TOAST_ID });

    localStorage.setItem(
      'p',
      btoa(PermissionService.flattenObject(authScopes).toString())
    );

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        permissions: PermissionService.flattenObject(authScopes)
      }
    });
  };

  const logout = async () => {
    await AuthService.logout();
    setSession();
    dispatch({ type: 'LOGOUT' });
    toast.success('Successfully logged out');
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
