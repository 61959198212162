import {
  pdf,
  Document,
  Page,
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontWeight: 'bold'
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 12,
    fontWeight: 'bold'
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10
  }
});

const createPDF = (data, format) => {
  let headers = format.map((item) => item.Header);
  return (
    <Document>
      <Page>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {headers.map((header) => (
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>{header}</Text>
              </View>
            ))}
          </View>
          {data.map((item) => (
            <View style={styles.tableRow}>
              {format.map((f) => {
                let value;
                if (typeof f.accessor === 'function') {
                  value = f.accessor(item);
                } else if (f.accessor.includes('.')) {
                  let keys = f.accessor.split('.');
                  value = item;
                  for (let i = 0; i < keys.length; i++) {
                    if (
                      value[keys[i]] === undefined ||
                      value[keys[i]] === null
                    ) {
                      value = null;
                      break;
                    }
                    value = value[keys[i]];
                  }
                } else {
                  value = item[f.accessor];
                }
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

const handleExportPdf = (data, csvFormat, csvFileName) => {
  const pdfDoc = createPDF(data, csvFormat);
  const asPdf = pdf([]);
  asPdf.updateContainer(pdfDoc);
  asPdf.toBlob().then((blob) => {
    saveAs(blob, `${csvFileName}.pdf`);
  });
};

export default handleExportPdf;
