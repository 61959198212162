const adminDivisionTypeOptions = ['ZONE', 'CIRCLE', 'WARD'];
const tagTypeOptions = ['QR', 'RFID'];
const geoPointTypeOptions = ['POINT', 'POLYGON'];
const genderTypeOptions = ['MALE', 'FEMALE', 'OTHERS'];
const routeTypeOptions = ['SWM', 'TRANSPORT'];
const bloodGroupTypeOptions = [
  'A+',
  'A-',
  'B+',
  'B-',
  'O+',
  'O-',
  'AB+',
  'AB-'
];

const VehicleCategory = [
  'PERSON',
  'BUS',
  'TRUCK',
  'SMALL_CAR',
  'BIKE',
  'MINI_VAN',
  'SEDAN',
  'SHIP',
  'SUV',
  'PERSON'
];

const VehicleStatus = ['ACTIVE', 'INACTIVE'];

const FuelType = [
  'PETROL',
  'DIESEL',
  'CNG',
  'ELECTRIC',
  'LPG',
  'Ethanol',
  'Hydrogen'
];
const ROLES = ['ADMIN', 'DISTRIBUTER', 'DEALER', 'CUSTOMER', 'VENDOR'];

const ALERT_TYPES = [
  { keys: 'general', values: 'General' },
  { keys: 'sos', values: 'SOS' },
  { keys: 'vibration', values: 'Vibration' },
  { keys: 'movement', values: 'Movement' },
  { keys: 'lowspeed', values: 'Low Speed' },
  { keys: 'overspeed', values: 'Overspeed' },
  { keys: 'fallDown', values: 'Fall Down' },
  { keys: 'lowPower', values: 'Low Power' },
  { keys: 'lowBattery', values: 'Low Battery' },
  { keys: 'fault', values: 'Fault' },
  { keys: 'powerOff', values: 'Power Off' },
  { keys: 'powerOn', values: 'Power On' },
  { keys: 'door', values: 'Door' },
  { keys: 'lock', values: 'Lock' },
  { keys: 'unlock', values: 'Unlock' },
  { keys: 'geofence', values: 'Geofence' },
  { keys: 'geofenceEnter', values: 'Geofence Enter' },
  { keys: 'geofenceExit', values: 'Geofence Exit' },
  { keys: 'gpsAntennaCut', values: 'GPS Antenna Cut' },
  { keys: 'accident', values: 'Accident' },
  { keys: 'tow', values: 'Tow' },
  { keys: 'idle', values: 'Idle' },
  { keys: 'highRpm', values: 'High RPM' },
  { keys: 'hardAcceleration', values: 'Hard Acceleration' },
  { keys: 'hardBraking', values: 'Hard Braking' },
  { keys: 'hardCornering', values: 'Hard Cornering' },
  { keys: 'laneChange', values: 'Lane Change' },
  { keys: 'fatigueDriving', values: 'Fatigue Driving' },
  { keys: 'powerCut', values: 'Power Cut' },
  { keys: 'powerRestored', values: 'Power Restored' },
  { keys: 'jamming', values: 'Jamming' },
  { keys: 'temperature', values: 'Temperature' },
  { keys: 'parking', values: 'Parking' },
  { keys: 'bonnet', values: 'Bonnet' },
  { keys: 'footBrake', values: 'Foot Brake' },
  { keys: 'fuelLeak', values: 'Fuel Leak' },
  { keys: 'tampering', values: 'Tampering' },
  { keys: 'removing', values: 'Removing' },
  { keys: 'ignitionOn', values: 'Ignition On' },
  { keys: 'ignitionOff', values: 'Ignition Off' },
  { keys: 'acOn', values: 'AC turned on' },
  { keys: 'acOff', values: 'AC turned off' }
];

export {
  adminDivisionTypeOptions,
  tagTypeOptions,
  geoPointTypeOptions,
  genderTypeOptions,
  bloodGroupTypeOptions,
  routeTypeOptions,
  VehicleCategory,
  VehicleStatus,
  FuelType,
  ROLES,
  ALERT_TYPES
};
