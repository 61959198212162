import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const DutyReportModule = new Module({
  id: 'summary-duty-report',
  name: 'Duty Report',
  path: 'duty-report',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/DutyReport'))
  )
});
