/* eslint-disable jsx-a11y/accessible-emoji */
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BrandInput from 'src/components/FormInputs/BrandInput';

function AddMoneyModal({ modalOpen, setModalOpen }) {
  const [accountNo, setAccountNo] = useState('129305001898');
  const [ifscCode, setIfscCode] = useState('ICICI0001293');
  const [branchName, setbranchName] = useState('A.J.C BOSE ROAD, KOLKATA');
  const [beneficiary, setBeneficiary] = useState('VENERA SOFTWARE (P) LTD');

  const handleCopyClick = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('text copied', text);
      })
      .catch((err) => {
        console.error('Error copying text to clipboard: ', err);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          Add Money to wallet
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 1 }} variant="body1">
          🌟 Recharge Your Wallet Now! 🌟
        </Typography>
        <Alert color="info" variant="outlined">
          <Typography variant="body2">
            After completing your payment to the bank account given below,
            please ensure to share the payment proof via email to{' '}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
              admin@traxsmart.in
            </Typography>{' '}
            with the subject{' '}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
              "Wallet Recharge"
            </Typography>
            .
          </Typography>
        </Alert>
        <Typography sx={{ fontWeight: 'bold', mt: 2 }} variant="body1">
          {' '}
          Bank Details
        </Typography>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <Grid container spacing={2}>
          <Grid item md={6}>
            <BrandInput
              sx={{ mb: 2 }}
              label="A/C BENEFICIARY"
              value={beneficiary}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleCopyClick(beneficiary)}
                      color="primary"
                      aria-label="Copy branchName"
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item md={6}>
            <BrandInput
              sx={{ mb: 2 }}
              label="A/C NO"
              value={accountNo}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleCopyClick(accountNo)}
                      color="primary"
                      aria-label="Copy branchName"
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item md={6}>
            <BrandInput
              sx={{ mb: 2 }}
              label="IFSC"
              value={ifscCode}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleCopyClick(ifscCode)}
                      color="primary"
                      aria-label="Copy branchName"
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item md={6}>
            <BrandInput
              sx={{ mb: 2 }}
              label="Branch Name"
              value={branchName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleCopyClick(branchName)}
                      color="primary"
                      aria-label="Copy branch name"
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddMoneyModal;
