import { Grid, Box, TextField } from '@mui/material';
import React from 'react';

function BrandInput({
  handleBlur,
  handleChange,
  value,
  type,
  helperText,
  error,
  fullWidth = true,
  label,
  placeholder,
  isDisabled,
  xs,
  md,
  readOnly = false,
  ...rest
}) {
  

  return (
    <Grid item xs={xs || 12} md={md || 12}>
      {label && (
        <Box pb={1}>
          <b>{label}:</b>
        </Box>
      )}
      <TextField
        error={error}
        fullWidth={fullWidth}
        helperText={helperText}
        type={type || 'text'}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        disabled={isDisabled}
        value={value}
        {...rest}
        InputProps={{
          readOnly,
          ...rest.InputProps
        }}
        margin="none"
      />
    </Grid>
  );
}

export default BrandInput;
