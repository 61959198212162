import { mock } from 'src/utils/axios';

let slaCompliance = [
  {
    VehicleName: 'C9/W62-B/RJ01GC4604',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C10/W75-B/RJ01GC4582',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'BUS/RJ01PA5404',
    vehicleType: 'BUS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C9/W61/RJ01GC4605',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C1/W7/RJ01GC4548',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C7/W43-B/RJ01GC4589',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'NTW/RJ01GC6294',
    vehicleType: 'TRAMWAY',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'TW/X/AUTO/5',
    vehicleType: 'TRAMWAY',
    online: 5,
    offline: 56,
    SLA_Compliance: '8.20%',
    onus_amc: 56,
    onus_vendor: 0
  },
  {
    VehicleName: 'C8/W57/RJ01GC4576',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C1/W5/RJ01GC4559',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'FB/RJ01EA0620',
    vehicleType: 'FIRE_BRIGADE',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C10/W78-B/RJ01GC4562',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C10/W76/RJ01GC4566',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C6/W39-A/RJ01GB7869',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C5/W31-B/RJ01GC4579',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'TW/Auto/9',
    vehicleType: 'TRAMWAY',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C2/W24-B/RJ01GB6879',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C5/W30-B/RJ01GC4580',
    vehicleType: 'NIGAM_TIPPERS',
    online: 7,
    offline: 54,
    SLA_Compliance: '11.48%',
    onus_amc: 54,
    onus_vendor: 0
  },
  {
    VehicleName: 'C3/W20/RJ01GB7888',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  },
  {
    VehicleName: 'C7/W47/RJ01GC4594',
    vehicleType: 'NIGAM_TIPPERS',
    online: 61,
    offline: 0,
    SLA_Compliance: '100.00%',
    onus_amc: 0,
    onus_vendor: 0
  }
];

mock.onGet('/api/slaCompliance').reply(() => {
  return [200, { slaCompliance }];
});
