import React, { Suspense } from 'react';
import SuspenseLoader from '../SuspenseLoader';

const ComponentLoader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default ComponentLoader;
