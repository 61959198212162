import numeral from 'numeral';

export default class MoneyService {
  static fromServer(amount, formatted = false) {
    if (amount && amount > 0) amount /= 100;

    if (formatted) return numeral(amount ?? 0).format('₹0,0.00');

    return parseFloat(amount ?? 0).toFixed(2);
  }

  static toServer(amount) {
    return parseInt((amount ?? 0) * 100);
  }
}
