import { WALLET_URL } from 'src/config/erp.api.config';
import ApiService from '../Api.service';

class WalletService extends ApiService {
  constructor() {
    super(WALLET_URL);
  }
  getTransactions(walletId, params = {}) {
    return this.doGet(`${this._url}/${walletId}/transactions`, params);
  }
  addMoney(userId, payload = {}) {
    return this.doPut(`${this._url}/add/${userId}`, payload);
  }
  deductMoney(userId, payload = {}) {
    return this.doPut(`${this._url}/deduct/${userId}`, payload);
  }
}

export default new WalletService();
