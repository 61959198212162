import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const data = [
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Rahul Sharma',
    vehicleName: 'Honda City - MH01AB1234',
    driverId: 'EMP001',
    overSpeedEventsCount: 5,
    hardCorneringEventsCount: 2,
    hardBrakingEventsCount: 3,
    hardAccelerationEventsCount: 4,
    drivingScore: 78
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Amit Patel',
    vehicleName: 'Maruti Swift - GJ05CD5678',
    driverId: 'EMP002',
    overSpeedEventsCount: 3,
    hardCorneringEventsCount: 1,
    hardBrakingEventsCount: 2,
    hardAccelerationEventsCount: 5,
    drivingScore: 86
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Sandeep Singh',
    vehicleName: 'Toyota Innova - DL07EF9012',
    driverId: 'EMP003',
    overSpeedEventsCount: 2,
    hardCorneringEventsCount: 3,
    hardBrakingEventsCount: 1,
    hardAccelerationEventsCount: 6,
    drivingScore: 72
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Rajesh Gupta',
    vehicleName: 'Tata Nexon - KA02GH3456',
    driverId: 'EMP004',
    overSpeedEventsCount: 6,
    hardCorneringEventsCount: 4,
    hardBrakingEventsCount: 2,
    hardAccelerationEventsCount: 3,
    drivingScore: 68
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Ankur Verma',
    vehicleName: 'Hyundai Creta - MH04IJ7890',
    driverId: 'EMP005',
    overSpeedEventsCount: 4,
    hardCorneringEventsCount: 2,
    hardBrakingEventsCount: 1,
    hardAccelerationEventsCount: 7,
    drivingScore: 90
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Arun Kumar',
    vehicleName: 'Mahindra XUV500 - UP32KL2345',
    driverId: 'EMP006',
    overSpeedEventsCount: 2,
    hardCorneringEventsCount: 1,
    hardBrakingEventsCount: 3,
    hardAccelerationEventsCount: 5,
    drivingScore: 82
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Nikhil Sharma',
    vehicleName: 'Ford EcoSport - RJ14MN4567',
    driverId: 'EMP007',
    overSpeedEventsCount: 1,
    hardCorneringEventsCount: 4,
    hardBrakingEventsCount: 2,
    hardAccelerationEventsCount: 4,
    drivingScore: 75
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Vikas Singh',
    vehicleName: 'Renault Kwid - HR26OP5678',
    driverId: 'EMP008',
    overSpeedEventsCount: 3,
    hardCorneringEventsCount: 2,
    hardBrakingEventsCount: 3,
    hardAccelerationEventsCount: 2,
    drivingScore: 80
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Gaurav Patel',
    vehicleName: 'Volkswagen Polo - TN01QR7890',
    driverId: 'EMP009',
    overSpeedEventsCount: 2,
    hardCorneringEventsCount: 3,
    hardBrakingEventsCount: 2,
    hardAccelerationEventsCount: 4,
    drivingScore: 73
  },
  {
    fromTime: '2023-07-24T08:00:00Z',
    toTime: '2023-07-25T08:00:00Z',
    driverName: 'Rakesh Gupta',
    vehicleName: 'Chevrolet Beat - DL09ST1234',
    driverId: 'EMP010',
    overSpeedEventsCount: 1,
    hardCorneringEventsCount: 2,
    hardBrakingEventsCount: 1,
    hardAccelerationEventsCount: 5,
    drivingScore: 88
  }
];

mock.onGet('/api/driver-performance').reply(() => {
  return [200, data];
});
