import { useContext } from 'react';
import AuthContext from 'src/contexts/JWTAuthContext';

const useAuth = () => {
  const auth = useContext(AuthContext);

  const permissions = atob(localStorage.getItem('p')).split(',');

  return { ...auth, permissions };
};

export default useAuth;
