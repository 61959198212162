import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

let complaints = [
  {
    id: uuid(),
    date: "7th May' 22",
    status: 'PRESENT',
    complaint: 'The waste in my house is not collected',
    address: 'Postmaster, Post Office DARGHA SHAREEF AJMER',
    ward: 'Ward 021',
    circle: 'Circle 015',
    citizenName: 'Sunny Kumar'
  },
  {
    id: uuid(),
    date: "8th May' 22",
    complaint: 'Uncollected garbage',
    address: 'CM89+WXR, Sedariya, Jaipur Road, Ajmer, Rajasthan 305001',
    ward: 'Ward 020',
    circle: 'Circle 07',
    citizenName: 'Parag Agrawal'
  },
  {
    id: uuid(),
    date: "13th May' 22",
    complaint: "It's been a week since waste collected from our restaurant",
    address: '177, Adarsh Nagar, Ajmer, Rajasthan 305002',
    ward: 'Ward 026',
    circle: 'Circle 013',
    citizenName: 'Bhanu Bharti'
  },
  {
    id: uuid(),
    date: "15th May' 22",
    complaint: 'Waste not collected from last week from my appartment',
    address: 'CM72+VCM, Vigyan Nagar, Ajmer, Rajasthan 305002',
    ward: 'Ward 01',
    circle: 'Circle 02',
    citizenName: 'Sapna Chaudhary'
  },
  {
    id: uuid(),
    date: "16th May' 22",
    complaint: 'dustbins are overflowing with waste. Please come and collect.',
    address: 'CM8C+X7W, Ajmer Bypass, Ratanzila, Ajmer, Rajasthan 305001',
    ward: 'Ward 016',
    circle: 'Circle 09',
    citizenName: 'Gaurav Saha'
  },
  {
    id: uuid(),
    date: "19th May' 22",
    complaint: "It's been a week since waste collected from our hotel",
    address:
      'Gyan Singh Smriti Market, Nasirabad Rd, Parbatpura, Ajmer, Rajasthan 305002',
    ward: 'Ward 026',
    circle: 'Circle 010',
    citizenName: 'Priya Yadav'
  },
  {
    id: uuid(),
    date: "20th May' 22",
    complaint: "It's been a week since waste collected from our appartment",
    address: '50a, Ashiana Rd, Adarsh Nagar, Ajmer, Rajasthan 305001',
    ward: 'Ward 045',
    circle: 'Circle 011',
    citizenName: 'Nighat Sharda'
  },
  {
    id: uuid(),
    date: "22th May' 22",
    complaint: 'Not clering the garbage clearly from bins ',
    address: 'CM45+32F, Ajmer Bypass, Parbatpura, Ajmer, Rajasthan 305002',
    ward: 'Ward 056',
    circle: 'Circle 09',
    citizenName: 'Deepak Maheshwari'
  },
  {
    id: uuid(),
    date: "23th May' 22",
    complaint: 'Garbage is not collected from my home ',
    address: 'CM67+R6W, Parbatpura, Industrial Area, Ajmer, Rajasthan 305001',
    ward: 'Ward 60',
    circle: 'Circle 010',
    citizenName: 'Raghuvendra Raj'
  },
  {
    id: uuid(),
    date: "24th May' 22",
    complaint:
      'Driver talking rude when I asked to clean the garbage that is fallen on the road while unfilling the dustbins',
    address:
      'Shop No, Nr Bharat pharma, 50A, Ashiana Rd, Adarsh Nagar, Ajmer, Rajasthan 305001',
    ward: 'Ward 45',
    circle: 'Circle 020',
    citizenName: 'Naina Verma'
  },
  {
    id: uuid(),
    date: "25th May' 22",
    complaint: 'Waste from my home is not collected today morning',
    address: 'Arjun Lal Sethi Nagar, Ajmer, Rajasthan 305002',
    ward: 'Ward 46',
    circle: 'Circle 015',
    citizenName: 'Vasudev Chaudhary'
  }
];

mock.onGet('/api/complaint').reply(() => {
  return [200, { complaints }];
});

mock.onGet('/api/complaint/:id').reply((config) => {
  const { productId } = config.params;
  const complaint = complaints.find((_product) => _product.id === productId);

  return [200, { complaint }];
});
