import { ROLE_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class RoleService extends ApiService {
  constructor() {
    super(ROLE_URL);
  }

  getWithPermissions(id) {
    const hydrate = 'permissions';
    const _params = { _id: id, hydrate };

    return this.doGetById(ROLE_URL, _params);
  }
}

export default new RoleService();
