import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

let attendances = [
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Shri Shankar',
    role: 'Driver',
    ward: 'Ward 012',
    circle: 'Circle 08',
    status: 'PRESENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Sunny Kumar',
    role: 'Driver',
    ward: 'Ward 021',
    circle: 'Circle 015',
    status: 'PRESENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Sushil Kumar',
    role: 'Driver',
    ward: 'Ward 036',
    circle: 'Circle 20',
    status: 'ABSENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Ranjit Gharu',
    role: 'Health Inspector',
    ward: 'Ward 018',
    circle: 'Circle 15',
    status: 'PRESENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Lalit Kumar',
    role: 'Supervisor',
    ward: 'Ward 012',
    circle: 'Circle 08',
    status: 'PRESENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Karan Kumar',
    role: 'Driver',
    ward: 'Ward 012',
    circle: 'Circle 08',
    status: 'PRESENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Jitendra Tiwari',
    role: 'Health Inspector',
    ward: 'Ward 018',
    circle: 'Circle 15',
    status: 'ABSENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Shri Satyanarayan Lakhan',
    role: 'Driver',
    ward: 'Ward 018',
    circle: 'Circle 15',
    status: 'PRESENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Amrit Kumar',
    role: 'Driver',
    ward: 'Ward 012',
    circle: 'Circle 08',
    status: 'PRESENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Shri Dhanraj Hada',
    role: 'Supervisor',
    ward: 'Ward 012',
    circle: 'Circle 08',
    status: 'ABSENT'
  },
  {
    id: uuid(),
    date: "7th May' 22",
    name: 'Shri Sitaram Joshi',
    role: 'Driver',
    ward: 'Ward 012',
    circle: 'Circle 08',
    status: 'PRESENT'
  }
];

mock.onGet('/api/attendance').reply(() => {
  return [200, { attendances }];
});

mock.onGet('/api/attendance/:id').reply((config) => {
  const { productId } = config.params;
  const attendance = attendances.find((_product) => _product.id === productId);

  return [200, { attendance }];
});
