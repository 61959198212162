import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import 'leaflet/dist/leaflet.css';
import { ConfirmProvider } from 'material-ui-confirm';

import './styles/custom.style.css';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (auth.isInitialized && auth?.user?.role === 'LIMITED_USER') {
      if (location.pathname === '/dashboard') {
        navigate('/vehicle-status', { replace: true });
      }
    }
  }, [auth.isInitialized]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfirmProvider>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <CssBaseline />
            {auth.isInitialized ? content : <AppInit />}
          </SnackbarProvider>
          <Toaster position="top-center" reverseOrder={false} />
        </ConfirmProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
