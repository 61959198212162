import { VEHICLE_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class VehicleService extends ApiService {
  constructor() {
    super(VEHICLE_URL);
  }

  getVehiclePosition = async (vehicleId) => {
    return this.doGet(
      `${VEHICLE_URL}/position/track`,
      { vehicleId },
      { ...this._headers }
    );
  };

  getVehicleSummary = async (params) => {
    return this.doGet(
      `${VEHICLE_URL}/summary`,
      { ...params },
      { ...this._headers }
    );
  };

  getVehiclePlayback = async (params) => {
    return this.doGet(
      `${VEHICLE_URL}/position/playback`,
      { ...params },
      { ...this._headers }
    );
  };

  getVehicleTimeline = async (query) => {
    return this.doGet(`${VEHICLE_URL}/trips/timeline`, query, {
      ...this._headers
    });
  };

  getVehicleStatus = async (query) => {
    return this.doGet(`${VEHICLE_URL}/statuses`, query, { ...this._headers });
  };
}

export default new VehicleService();
